import React from 'react';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import { DateTimeCell, TextCell } from '../components/cells';
import { formatRut, formatMoney } from '../helpers';
import DicomComponent from '../components/dicom/DicomComponent';

const LegalRepresentativeColumns = [
  {
    field: 'name',
    type: 'string',
    headerName: 'Nombre',
    renderCell: ({ row }) => <TextCell text={row.relatedPerson.name} />,
    sortable: true,
    flex: 2,
  },
  {
    field: 'rut',
    type: 'string',
    headerName: 'Rut',
    renderCell: ({ row }) => <TextCell text={formatRut(row.relatedPerson.rut)} />,
    sortable: true,
    flex: 0.7,
  },
  {
    field: 'partnerType',
    type: 'string',
    headerName: 'Tipo',
    renderCell: ({ row }) => (
      <Stack direction="column" spacing={0.7}>
        {row.isLegalRepresentative && <Typography>Rep. Legal</Typography>}
        {row.isPartner && <Typography>Socio</Typography>}
        {row.isCurrentSociety && <Typography>Sociedad Vigente</Typography>}
      </Stack>
    ),
    sortable: true,
    flex: 0.7,
  },
  {
    field: 'dicomDetails',
    headerName: 'Dicom',
    type: 'singleSelect',
    align: 'left',
    renderCell: ({ row }) => {
      const dicom = row?.relatedPerson?.currentDicom;
      const dicomsPlatinum = row?.relatedPerson?.currentDicomPlatinum;
      const createdAt = dicom?.createdAt ?? dicomsPlatinum?.createdAt;
      const amount = dicom?.dicomAmount ?? dicomsPlatinum?.dicomAmount;
      const isNaturalPersonRut = row?.relatedPerson?.isNaturalPersonRut;
      return (
        <Stack direction="row" spacing={2}>
          <DicomComponent
            isNaturalPersonRut={isNaturalPersonRut}
            masterEntityId={row.relatedPerson.id}
            disabled={createdAt === undefined}
          />
          {createdAt
          && (
          <Stack direction="column" alignSelf="center">
            <DateTimeCell fullDate={moment(createdAt)} />
            <Typography>${formatMoney(amount)}</Typography>
          </Stack>
          )}
        </Stack>
      );
    },
    flex: 1,
  },
];

export default LegalRepresentativeColumns;
