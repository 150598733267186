export const CLIENT_FACTORING_COLUMNS = [
  'simpleFolio',
  'receiver_Name',
  'cessionStatus',
  'amountWithIva',
  'siiStatus',
  'operationDate',
  'offerDateToPay',
  'amountUsedInEntityLine',
  'actionsDrawer',
  'ratificationActions',
];

export const CLIENT_ORDERING_COLUMNS = [
  'orderNumber',
  'purchaser_Name',
  'orderingoffer_OrderingFinancedAmount',
  'totalAmount',
  'orderingoffer_InvoiceIssuedDate',
  'orderingoffer_InvoiceDateToPay',
  'orderingStatus',
  'actionsDrawer',
];

export const DEBTOR_FACTORING_COLUMNS = [
  'simpleFolio',
  'company_Name',
  'cessionStatus',
  'amountWithIva',
  'siiStatus',
  'operationDate',
  'offerDateToPay',
  'actionsDrawer',
  'ratificationActions',
];
